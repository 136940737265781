import React, { useState, useEffect } from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../Elements/Footer';

import linkedin from '../../assets/media/home/linkedin.svg'

const Home = () => {
    var footerPreviousPage = {link: "https://www.linkedin.com/company/kolaghassicapital", text: <img src={linkedin} />};
    var footerNextPage = {link: "about", text: "About Us", index: 1};
    return (
        <>
            <div className="welcome-container">
                <h1>Inspired<br /><span>Investment Insight</span></h1>
                <p>A single-family office advisory firm solely dedicated to preserve and grow the assets under management of <strong>Kolaghassi International Holdings Limited</strong>.</p>
                <p><strong>Kolaghassi Capital</strong> engages in a broad range of investment activities through offices in Dubai and Washington D.C. and affiliated offices throughout the world.</p>

                <ButtonToolbar>
                    <Link to="about" style={{marginRight: '1rem'}} className="btn btn-outline-primary">Learn More</Link>
                    <Link to="contact" className="btn btn-primary">Contact Us</Link>
                </ButtonToolbar>
                
            </div>
            <Footer previousPage={footerPreviousPage} nextPage={footerNextPage} />
        </>
    );
};

export default Home;