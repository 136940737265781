import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Footer from '../Elements/Footer';
import OrganizationList from '../Elements/OrganizationList';

const Foundation = (props) => {
    var footerNextPage = {link: "/contact", text: "Contact", index: 14};

    const accessSecure = localStorage.getItem('token');
    var footerPreviousPage;

    if( accessSecure ){
        footerPreviousPage = {link: "/portfolio/venture-capital", text: "Venture Capital", index: 12};
    }else{
        footerPreviousPage = {link: "/about/investment-locations", text: "Investment Locations", index: 6};
    }


    return (
        <>
            <Row className="mb-4">
                <Col md={6}>
                    <h1>Ali & Lama<br /><span className="accent">Kolaghassi<br />Foundation</span></h1>
                </Col>
                <Col className="pt-lg-5" md={5}>
                    <p>We are passionate about social philanthropy and are proud to support and contribute to societies and make a positive impact on our communities as well as the lives and futures of people around the world. We stimulate a culture where company growth and social development go hand in hand.</p>
                </Col>
            </Row>
            <OrganizationList data={props.pageData.organizations} />

            <Footer nextPage={footerNextPage ? footerNextPage : null} previousPage={footerPreviousPage} />
        </>
    );
};

export default Foundation;