
import slugify from 'react-slugify';

// Advisory Board
import FourSeasonsattheSurfClubPhoto from '../assets/media/portfolio/real-estate-and-hospitality/FourSeasonsattheSurfClub.png';
import FourSeasonsFortLauderdalePhoto from '../assets/media/portfolio/real-estate-and-hospitality/FourSeasonsFortLauderdale.png';
import FourSeasonsPalmBeachPhoto from '../assets/media/portfolio/real-estate-and-hospitality/FourSeasonsPalmBeach.png';
import TheLoutrelPhoto from '../assets/media/portfolio/real-estate-and-hospitality/TheLoutrel.png';

import WAmmanPhoto from '../assets/media/portfolio/real-estate-and-hospitality/WAmman.png';
import TheWestinAqabaPhoto from '../assets/media/portfolio/real-estate-and-hospitality/TheWestinAqaba.png';
import AlManaraAqabaaLuxuryCollectionHotelPhoto from '../assets/media/portfolio/real-estate-and-hospitality/AlManaraAqabaaLuxuryCollectionHotel.png';
import JumeriahMuscatBayPhoto from '../assets/media/portfolio/real-estate-and-hospitality/JumeriahMuscatBay.png';

import SouthWarnerPhoto from '../assets/media/portfolio/real-estate-and-hospitality/150SouthWarner.png';
import WestEriePhoto from '../assets/media/portfolio/real-estate-and-hospitality/363WestErie.png';
import JuddHousePhoto from '../assets/media/portfolio/real-estate-and-hospitality/JuddHouse.png';

import BurjDamanPhoto from '../assets/media/portfolio/real-estate-and-hospitality/BurjDaman.png';
import TiffanyTowerPhoto from '../assets/media/portfolio/real-estate-and-hospitality/TiffanyTower.png';

import CVSGroundLeasePhoto from '../assets/media/portfolio/real-estate-and-hospitality/CVSGroundLease.png';
import GeorgetownShopsPhoto from '../assets/media/portfolio/real-estate-and-hospitality/GeorgetownShops.png';
import DavisFordCrossingPhoto from '../assets/media/portfolio/real-estate-and-hospitality/DavisFordCrossing.png';
import WesTechVillageCornerPhoto from '../assets/media/portfolio/real-estate-and-hospitality/WesTechVillageCorner.png';

import AlHayaCompoundPhoto from '../assets/media/portfolio/real-estate-and-hospitality/AlHayaCompound.png';

const portfolioData = [
  {
    name: "Four Seasons at the Surf Club",
    key: slugify("Four Seasons at the Surf Club"),
    type: 'hospitality',
    location: "United States",
    description: <>
      <p>9001 Collins Avenue, Surfside, FL 33154</p><p>77 key hotel & 150 private residences</p>
      <p><strong className="accent">Lead Partner:</strong> Fort Partners</p>
    </>,
    image: FourSeasonsattheSurfClubPhoto
  },
  {
    name: "Four Seasons Fort Lauderdale",
    key: slugify("Four Seasons Fort Lauderdale"),
    type: 'hospitality',
    location: "United States",
    description: <>
      <p>525 N. Fort Lauderdale Beach Blvd, FL 33304</p><p>130 key hotel & 90 private residences</p>
      <p><strong className="accent">Lead Partner:</strong> Fort Partners</p>
    </>,
    image: FourSeasonsFortLauderdalePhoto
  },
  {
    name: "Four Seasons Palm Beach",
    key: slugify("Four Seasons Palm Beach"),
    type: 'hospitality',
    location: "United States",
    description: <>
      <p>2800 S. Ocean Blvd, Palm Beach, FL 33480</p><p>210 key hotel</p>
      <p><strong className="accent">Lead Partner:</strong> Fort Partners</p>
    </>,
    image: FourSeasonsPalmBeachPhoto
  },
  {
    name: "The Loutrel",
    key: slugify("The Loutrel"),
    type: 'hospitality',
    location: "United States",
    description: <>
      <p>61 State Street, Charleston, SC 29401</p><p>50 key hotel</p>
      <p><strong className="accent">&nbsp;</strong></p>
      <p><strong className="accent">Lead Partner:</strong> Spaulding & Slye Investments</p>
    </>,
    image: TheLoutrelPhoto
  },
  {
    name: "W Amman",
    key: slugify("W Amman"),
    type: 'hospitality',
    location: "Middle East",
    description: <>
      <p>13 Rafiq Al Hariri Ave, Amman, Jordan 11190</p><p>280 key hotel</p>
      <p><strong className="accent">Lead Partner:</strong> Eagle Hills</p>
    </>,
    image: WAmmanPhoto
  },
  {
    name: "The Westin Aqaba",
    key: slugify("The Westin Aqaba"),
    type: 'hospitality',
    location: "Middle East",
    description: <>
      <p>Plot V, Saraya Aqaba, Jordan 1968</p><p>300 key hotel</p>
      <p><strong className="accent">Lead Partner:</strong> Eagle Hills</p>
    </>,
    image: TheWestinAqabaPhoto
  },
  {
    name: "Al Manara Aqaba, a Luxury Collection Hotel",
    key: slugify("Al Manara Aqaba, a Luxury Collection Hotel"),
    type: 'hospitality',
    location: "Middle East",
    description: <>
      <p>Al-Hashemi Street, Aqaba, Jordan 1968</p><p>207 key hotel</p>
      <p><strong className="accent">Lead Partner:</strong> Eagle Hills</p>
    </>,
    image: AlManaraAqabaaLuxuryCollectionHotelPhoto
  },
  {
    name: "Jumeriah Muscat Bay",
    key: slugify("Jumeriah Muscat Bay"),
    type: 'hospitality',
    location: "Middle East",
    description: <>
      <p>Bandar Jissah, Oman</p><p>206 key hotel</p>
      <p><strong className="accent">Lead Partner:</strong> Eagle Hills</p>
    </>,
    image: JumeriahMuscatBayPhoto
  },
  {
    name: "150 South Warner",
    key: slugify("150 South Warner"),
    type: 'office',
    location: "USA & United Kingdom",
    description: <>
      <p>150 South Warner Rd, King of Prussia, PA 19406</p>
      <p><strong className="accent">Office Building</strong></p>
    </>,
    image: SouthWarnerPhoto
  },
  {
    name: "Judd House",
    key: slugify("Judd House"),
    type: 'office',
    location: "USA & United Kingdom",
    description: <>
      <p>18-29 Mora Street, London EC1V 8BT</p>
      <p><strong className="accent">Office Building</strong></p>
    </>,
    image: JuddHousePhoto
  },
  {
    name: "363 West Erie",
    key: slugify("363 West Erie"),
    type: 'office',
    location: "USA & United Kingdom",
    description: <>
      <p>363 West Erie St, Chicago, IL 60654</p>
      <p><strong className="accent">Office Building</strong></p>
    </>,
    image: WestEriePhoto
  },
  {
    name: "Burj Daman",
    key: slugify("Burj Daman"),
    type: 'office',
    location: "United Arab Emirates",
    description: <>
      <p>312 Al Mustaqbal Street, Za’abeel, Dubai, United Arab Emirates</p>
      <p><strong className="accent">Office Building - 6th Floor</strong></p>
    </>,
    image: BurjDamanPhoto
  },
  {
    name: "Tiffany Tower",
    key: slugify("Tiffany Tower"),
    type: 'office',
    location: "United Arab Emirates",
    description: <>
      <p>Jumeirah Lake Towers, Dubai, United Arab Emirates</p>
      <p><strong className="accent">Office Building - 28th Floor</strong></p>
    </>,
    image: TiffanyTowerPhoto
  },
  {
    name: "CVS",
    key: slugify("CVS"),
    type: 'retail',
    location: "United States",
    description: <>
      <p>8117 Leesburg Pike, Vienna, Virginia 22182</p>
      <p><strong className="accent">Lead Investor:</strong> Kolaghassi Group</p>
      <p><strong className="accent">Partner & Asset Manager:</strong> Rappaport</p>
    </>,
    image: CVSGroundLeasePhoto
  },
  {
    name: "Georgetown Shops",
    key: slugify("Georgetown Shops"),
    type: 'retail',
    location: "United States",
    description: <>
      <p>1815-1825 Wisconsin Ave, DC 20007</p>
      <p>One-story retail strip center</p>
      <p><strong className="accent">Lead Investor:</strong> Kolaghassi Group</p>
      <p><strong className="accent">Partner & Asset Manager:</strong> Rappaport</p>
    </>,
    image: GeorgetownShopsPhoto
  },
  {
    name: "Davis Ford Crossing",
    key: slugify("Davis Ford Crossing"),
    type: 'retail',
    location: "United States",
    description: <>
      <p>9890 Liberia Ave, Manassas, Virginia 20110</p>
      <p>Suburban retail shopping center</p>
      <p><strong className="accent">Lead Investor:</strong> Kolaghassi Group</p>
      <p><strong className="accent">Partner & Asset Manager:</strong> Rappaport</p>
    </>,
    image: DavisFordCrossingPhoto
  },
  {
    name: "WesTech Village Corner",
    key: slugify("WesTech Village Corner"),
    type: 'retail',
    location: "United States",
    description: <>
      <p>12251 Tech Rd, Silver Spring, Maryland 20904</p>
      <p>Suburban retail shopping center</p>
      <p><strong className="accent">Lead Investor:</strong> Kolaghassi Group</p>
      <p><strong className="accent">Partner & Asset Manager:</strong> Rappaport</p>
    </>,
    image: WesTechVillageCornerPhoto
  },
  {
    name: "Al Haya Compound",
    key: slugify("Al Haya Compound"),
    type: 'residential',
    location: "Middle East",
    description: <>
      <p>Riyadh, Saudi Arabia</p><p>A residential compound, consisting of 27 villas and 2 VIP villas in a highly sought-after neighborhood, with amenities including a recreation center, cinema, swimming pool, and squash court.</p>
    </>,
    image: AlHayaCompoundPhoto
  }
];

export default portfolioData;