import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Footer from '../Elements/Footer';

import graph from '../../assets/media/investment-landscape/graph.svg';

const AboutInvestmentLandscape = () => {
    var footerPreviousPage = {link: "/management/management", text: "Executive Management", index: 4};
    var footerNextPage = {link: "/about/investment-locations", text: "Investment Locations", index: 6};

    return (
        <>
            <h1>Investment<br /><span>Landscape</span></h1>

            <div className="investment-landscape-graph" style={{'backgroundImage': 'url('+graph+')'}}>
                <p className="label label-large label-realestatehospitality">Real Estate<br />& Hospitality</p>
                <p className="label label-technology">Technology</p>
                <p className="label label-softwareit">Software & IT</p>
                <p className="label label-others">Others</p>
                <p className="label label-healthcare">Healthcare</p>
                <p className="label label-bankingfinancialservices">Banking &<br />Financial Services</p>
                <p className="label label-foodbeverages">Food & Beverage</p>
                <p className="label label-energy">Energy</p>
            </div>

            <Footer previousPage={footerPreviousPage} nextPage={footerNextPage} />
        </>
    );
};

export default AboutInvestmentLandscape;