import React, { useState, useEffect } from 'react';

import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';


import {
  Link
  } from "react-router-dom";

class Footer extends React.Component {
    render() {
        return <footer className="mt-5">
          <Row>
            { this.props.previousPage &&
              <Col xs={{span: 6}} className={ `previous px-sm-0${this.props.previousPage.index ? ' index' : ''}`}>
                { this.props.previousPage &&
                  <>
                  {
                    this.props.previousPage.link.substr(0, 4) === 'http' ?
                      <a className={ typeof this.props.previousPage.text == 'object' && this.props.previousPage.text.type == 'img' ? 'image' : ''} target="_blank" href={this.props.previousPage.link}>{ this.props.previousPage.index && <span>{this.props.previousPage.index}</span>}{this.props.previousPage.text}</a>
                      :
                      <Link className={ typeof this.props.previousPage.text == 'object' && this.props.previousPage.text.type == 'img' ? 'image' : ''} to={this.props.previousPage.link}>{ this.props.previousPage.index && <span>{this.props.previousPage.index}</span>}{this.props.previousPage.text}</Link>
                  }
                  </>
                }
              </Col>
            }
            
            { this.props.nextPage &&
              <Col xs={{span: 6, offset: ( this.props.previousPage ? 0 : 6 )}} className={ `next text-end px-sm-0${this.props.nextPage.index ? ' index' : ''}`}>
                { this.props.nextPage &&
                  <Link className={ typeof this.props.nextPage.text == 'object' && this.props.nextPage.text.type == 'img' ? 'image' : ''} to={this.props.nextPage.link}>{ this.props.nextPage.index && <span>{this.props.nextPage.index}</span>}{this.props.nextPage.text}</Link>
                }
              </Col>
            }
          </Row>
      </footer>
    }
}

export default Footer;