import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import Footer from '../Elements/Footer';
import PortfolioList from '../Elements/PortfolioList';
import slugify from 'react-slugify';
import { Redirect, useParams } from 'react-router';

import {
    Link,
      NavLink,
      useLocation
    } from "react-router-dom";
    
const PortfolioRealEstateAndHospitality = (props) => {
    const { typeKey, locationKey } = useParams();

    var portfolioDataFiltered = props.pageData.portfolio.filter( i => [typeKey].includes( i.type ) );
  
    const locations = [];

    portfolioDataFiltered.forEach(element => {
        if( !locations.includes(element.location) )
        {
            locations.push(element.location);
        }
    });

    var selectedLocation = locationKey;

    if( !selectedLocation )
    {
        selectedLocation = slugify(locations[0]);
    }

    portfolioDataFiltered = portfolioDataFiltered.filter( i => [selectedLocation].includes( slugify(i.location) ) );

    var title;
    var footerPreviousPage;
    var footerNextPage;

    if( typeKey === 'hospitality' )
    {
        title = 'Hospitality';
        footerPreviousPage = {link: "/about/investment-locations", text: "Investment Locations", index: 6};
        footerNextPage = {link: "/portfolio/real-estate-and-hospitality/office", text: "Office", index: 8};        
    }
    else if( typeKey === 'office' )
    {
        title = 'Office';
        footerPreviousPage = {link: "/portfolio/real-estate-and-hospitality/hospitality", text: "Hospitality", index: 7};
        footerNextPage = {link: "/portfolio/real-estate-and-hospitality/retail", text: "Retail", index: 9};
    }
    else if( typeKey === 'retail' )
    {
        title = 'Retail';
        footerPreviousPage = {link: "/portfolio/real-estate-and-hospitality/office", text: "Office", index: 8};
        footerNextPage = {link: "/portfolio/real-estate-and-hospitality/residential", text: "Residential", index: 10};
    }
    else if( typeKey === 'residential' )
    {
        title = 'Residential';
        footerPreviousPage = {link: "/portfolio/real-estate-and-hospitality/retail", text: "Retail", index: 9};
        footerNextPage = {link: "/portfolio/private-equity", text: "Private Equity", index: 11};
    }

    return (
        <>
            <h1>{title}</h1>
            
            <Nav className="tabs">
            { locations &&
                locations.map( (location, locationIndex) => (
                    <Nav.Item key={locationIndex}>
                        <Nav.Link className={ `nav-link${slugify(location) === selectedLocation ? ' active' : ''}`} as={NavLink} to={ `/portfolio/real-estate-and-hospitality/${typeKey}/${slugify(location)}`}>{location}</Nav.Link>
                    </Nav.Item>
                ))
            }
            </Nav>

            <PortfolioList data={portfolioDataFiltered} />
            <Footer previousPage={footerPreviousPage ? footerPreviousPage : null} nextPage={footerNextPage ? footerNextPage : null} />
        </>
    );
};

export default PortfolioRealEstateAndHospitality;