import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import {
  useHistory,
  BrowserRouter as Router
} from "react-router-dom";

import './Bootstrap.scss';

ReactDOM.render(
  <Router><App /></Router>,
  document.getElementById('root')
);

reportWebVitals();
