import React, { useState, useEffect } from 'react';

import { Row } from 'react-bootstrap';
import PortfolioCard from './PortfolioCard';

class PortfolioList extends React.Component {
  
    render() {
        return <Row>
          {this.props.data.map( (portfolioItem, portfolioIndex) => (
              <PortfolioCard key={portfolioIndex} data={portfolioItem} />
          ))}  
        </Row>
    }
}

export default PortfolioList;