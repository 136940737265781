import React, { useState, useEffect } from 'react';

import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import slugify from 'react-slugify';

import logo from '../../assets/img/logo.svg';

import {
  Link,
    NavLink,
    useLocation
  } from "react-router-dom";

class Header extends React.Component {
    state = { isActive: false };

    handleToggle = () => {
      this.setState({ isActive: !this.state.isActive });
    };

    render() {
        return <>
        <header className="mb-3 border-bottom d-none d-lg-block">
          <Container>
            <Row>
              <Col md={3}>
                <Link className="logo" to="/"><img src={logo} /></Link>
              </Col>
              <Col md={6}>
                <Navbar>
                  <Nav className="mx-auto">
                    {this.props.links.filter(route => !(( route.secure === true ) && !localStorage.getItem('token'))).map(route => (
                      <div key={slugify(route.name)} className={ `nav-link-container${route.childPages ? ' expandable' : ''}`}>
                        <Nav.Link
                          key={route.path}
                          as={NavLink}
                          to={route.path}
                          activeClassName="active"
                        >
                          <span>{route.name}</span>
                          </Nav.Link>
                          { route.childPages &&
                            <div className="nav-vertical">
                            {route.childPages.filter(childPage => !(( childPage.secure === true ) && !localStorage.getItem('token'))).map(childPage => (
                              <div key={slugify(childPage.name)} className={ `nav-vertical-link-container${childPage.childPages ? ' expandable' : ''}`}>
                                <Link to={childPage.path}><span>{childPage.name}</span></Link>
                                { childPage.childPages &&
                                  <div className="nav-horizontal">

                                  {childPage.childPages.map(childPageSub => (
                                    <div key={slugify(childPageSub.name)} className="nav-horizontal-link-container">
                                      <Link to={childPageSub.path}><span>{childPageSub.name}</span></Link>
                                    </div>
                                  ))}

                                  </div>
                                }
                              </div>
                            ))}
                            </div>
                          }
                      </div>
                    ))}
                  </Nav>
                </Navbar>
              </Col>
              <Col md={3}>
                <Link className="btn mt-4 btn-primary float-end" to="/contact">Contact</Link>
              </Col>
            </Row>
          </Container>
        </header>
        <header className="mobile d-block d-lg-none">
        <Container>
            <Row className=" border-bottom">
              <Col xs={6}>
                <Link className="logo" to="/"><img src={logo} /></Link>
              </Col>
              <Col xs={6} className="text-end">
                <button onClick={this.handleToggle} className="btn btn-outline-primary"><i className="bi bi-list"></i></button>
              </Col>
            </Row>
            <Row data-show={this.state.isActive ? "true" : "false"} className="border-bottom">
              <Col xs={12} className="p-0">
                <Navbar>
                  <Nav>
                    {this.props.links.filter(route => !(( route.secure === true ) && !localStorage.getItem('token'))).map(route => {


                      return <div key={slugify(route.name)} className={ `nav-mobile-link-container${route.childPages ? ' expandable' : ''}`}>
                        <Nav.Link onClick={this.handleToggle} key={route.path} as={NavLink} to={route.path} activeClassName="active">
                          <span>{route.name}</span>
                          </Nav.Link>
                          { route.childPages &&
                            <div className="nav-mobile-vertical">
                            {route.childPages.filter(childPage => !(( childPage.secure === true ) && !localStorage.getItem('token'))).map(childPage => {
                              return <div key={slugify(childPage.name)} className={ `nav-mobile-vertical-link-container${childPage.childPages ? ' expandable' : ''}`}>
                                <Link onClick={this.handleToggle} to={childPage.path}><span>{childPage.name}</span></Link>
                                { childPage.childPages &&
                                  <div className="nav-mobile-horizontal">

                                  {childPage.childPages.map(childPageSub => (
                                    <div key={slugify(childPageSub.name)} className="nav-mobile-horizontal-link-container">
                                      <Link onClick={this.handleToggle} to={childPageSub.path}><span>{childPageSub.name}</span></Link>
                                    </div>
                                  ))}

                                  </div>
                                }
                              </div>
                              })}
                            </div>
                          }
                      </div>
                    })}
                    <Nav.Link onClick={this.handleToggle} as={NavLink} to="/contact" activeClassName="active">
                      <span>Contact</span>
                    </Nav.Link>
                  </Nav>
                </Navbar>
              </Col>

            </Row>
          </Container>
        </header>
      </>
    }
}

export default Header;