import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Footer from '../Elements/Footer';

import DubaiPhoto from '../../assets/media/contact/dubai.png';
import WashingtonPhoto from '../../assets/media/contact/washington.png';
import { Link } from 'react-router-dom';

const Contact = (props) => {
    var footerPreviousPage = {link: "/foundation", text: "Foundation", index: 13};

    return (
        <>
            <Row className="mb-4">
                <Col xs={12} md={12} lg={4}>
                    <h1>Contact</h1>
                </Col>
                <Col xs={12} md={6} lg={4} className="px-4">
                    <img className="img-fluid mb-4" src={DubaiPhoto} />
                    <h6>Dubai, United Arab Emirates</h6>
                    <p>Emirates Financial Towers -DIFC<br />2101 South Level<br />Dubai - United Arab Emirates<br />Phone: <a href="tel:+9714-552-7570">+9714-552-7570</a></p>

                    <a className="btn-email btn btn-primary" href="mailto:contact@kolaghassicapital.com">Contact Us</a>
                </Col>
                <Col xs={12} md={6} lg={4} className="px-4 mt-4 mt-md-0">
                    <img className="img-fluid mb-4" src={WashingtonPhoto} />
                    <h6>Washington DC, United States</h6>
                    <p>P.O. Box 5584<br />Washington, DC 20016 - United States</p>

                    <a className="btn-email btn btn-primary" href="mailto:contact@kolaghassicapital.com">Contact Us</a>
                </Col>
            </Row>

            <Footer previousPage={footerPreviousPage} />
        </>
    );
};

export default Contact;