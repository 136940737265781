import React, { useState, useEffect } from 'react';
import PersonCard from '../Elements/PersonCard';
import PersonList from '../Elements/PersonList';
import Footer from '../Elements/Footer';

const ManagementManagement = (props) => {
    var footerPreviousPage = {link: "/management/advisory-board", text: "Advisory Board", index: 3};
    var footerNextPage = {link: "/about/investment-landscape", text: "Investment Landscape", index: 5};

    return (
        <>
            <h1>Executive <span>Management</span></h1>
            <PersonList data={props.pageData.people} />
            <Footer previousPage={footerPreviousPage} nextPage={footerNextPage} />
        </>
    );
};

export default ManagementManagement;