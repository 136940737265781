import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';

import { Col, Link, Row } from 'react-bootstrap';

import linkedinLogo from '../../assets/img/linkedin.svg';

class OrganizationCard extends React.Component {

    render() {
        if( this.props.data.link )
        {
            return <a target="_blank" component={Col} href={this.props.data.link} className="organization mb-4 text-center"><div className="image" style={{backgroundImage: `url(${this.props.data.image})`}}></div></a>

        }
        else
        {
            return <Col className="organization mb-4 text-center"><div className="image" style={{backgroundImage: `url(${this.props.data.image})`}}></div></Col>
        }
    }
}

export default OrganizationCard;