// Relationships
import Photo1 from '../assets/media/foundation/1.png';
import Photo2 from '../assets/media/foundation/2.png';
import Photo3 from '../assets/media/foundation/3.png';
import Photo4 from '../assets/media/foundation/4.png';
import Photo5 from '../assets/media/foundation/5.png';
import Photo6 from '../assets/media/foundation/6.png';
import Photo7 from '../assets/media/foundation/7.png';
import Photo8 from '../assets/media/foundation/8.png';
import Photo9 from '../assets/media/foundation/9.png';
import Photo10 from '../assets/media/foundation/10.png';

const ventureCapitalData = [
  // Investments
  {
    image: Photo1,
    link: "https://www.georgetown.edu"
  },
  {
    image: Photo2,
    link: "https://www.gwu.edu"
  },
  {
    image: Photo3,
    link: "https://www.kingsacademy.edu.jo"
  },
  {
    image: Photo4,
    link: "https://sos-jordan.org/en/"
  },
  {
    image: Photo5,
    link: "https://www.khcc.jo"
  },
  {
    image: Photo6,
    link: "https://www.tua.jo/en"
  },
  {
    image: Photo7,
    link: "https://www.ulyp.org"
  },
  {
    image: Photo8,
    link: "https://www.pacescharity.org"
  },
  {
    image: Photo9,
    link: "https://www.jordanriver.jo/en"
  },
  {
    image: Photo10,
    link: "https://dca.org.sa/pages/29"
  }
];

export default ventureCapitalData;

  