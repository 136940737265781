
// Advisory Board
import advisoryBoardPhoto1 from '../assets/media/management/advisory-board/photo1.png';
import advisoryBoardPhoto2 from '../assets/media/management/advisory-board/photo2.png';
import advisoryBoardPhoto3 from '../assets/media/management/advisory-board/photo3.png';
import advisoryBoardPhoto4 from '../assets/media/management/advisory-board/photo4.png';
import advisoryBoardPhoto5 from '../assets/media/management/advisory-board/photo5.png';
import advisoryBoardPhoto6 from '../assets/media/management/advisory-board/photo6.png';

// Executive Management
import executiveManagementPhoto1 from '../assets/media/management/executive-management/photo1.png';
import executiveManagementPhoto2 from '../assets/media/management/executive-management/photo2.png';
import executiveManagementPhoto3 from '../assets/media/management/executive-management/photo3.png';

const peopleData = [
    {
      name: "Ali H. Kolaghassi",
      key: "ali-h-kolaghassi",
      type: "advisory-board",
      position: <>Founder & President of<br />Kolaghassi Capital LTD</>,
      positionLong: <>Founder & President of Kolaghassi Capital LTD</>,
      description:    <>
                          <p><strong>Kolaghassi International Holdings</strong> - Chairman<br />
                          <strong>Ossia</strong> - Chairman of the Board of Directors<br />
                          <strong>Alcazar Capital</strong> - Member of the Board of Directors<br />
                          <br />
                          </p>
                          <p>
                          <strong>King’s Academy</strong> - Chairman of the Board of Trustees<br />
                          <strong>The George Washington University</strong> - Member of the Board of Trustees<br />
                          <strong>King Hussien Cancer Center</strong> - Member of the Board of Trustees<br /><br /></p>

                          <p><strong>Education:</strong> The George Washington University- BBA, International Business</p>
                      </>,
      descriptionLong: <>
        <p>With a vision that focuses on assessing and investing in businesses with high growth and revenue potential, Ali Kolaghassi, through KC, fully utilizes his talents for business creation and development to identify and pursue opportunities from concept through to implementation and exit. </p>
        <p>Ali Kolaghassi has had an illustrious career with over 29 years of leadership and management experience in corporate business development with a focus on construction, real estate development, asset management, investments, banking and telecommunication at an international level. </p>
    </>,
      /*descriptionLong: <>
        <p>With a vision that focuses on assessing and investing in businesses with high growth and revenue potential, Kolaghassi, through KC, fully utilizes his talents for business creation and development to identify and pursue opportunities from concept through to implementation and exit. </p>
        <p>Kolaghassi has had an illustrious career with over 29 years of leadership and management experience in corporate business development with a focus on construction, real estate development, asset management, investments, banking and telecommunication at an international level. </p>

        <h5 className="mb-3 mt-5">Boards</h5>
        <p><strong className="accent">Ossia</strong> - Chairman of the Board of Directors</p>
        <p><strong className="accent">Alcazar Capital</strong> - Member of the Board of Directors</p>

        <h5 className="mb-3 mt-5">Non-for-profit organizations</h5>
        <p><strong className="accent">King’s Academy</strong> - Chairman of the Board of Trustees</p>
        <p><strong className="accent">The George Washington University</strong> - Member of the Board of Trustees</p>
        <p><strong className="accent">King Hussien Cancer Center</strong> - Member of the Board of Trustees</p>
        <p>Kolaghassi received a BBA. in International Business from the George Washington University</p>
    </>,*/
    linkedinUrl: "https://www.linkedin.com/in/alikolaghassi/",
      image: advisoryBoardPhoto6
    },
    {
      name: "Abbas F. Zuaiter",
      key: "abbas-f-zuaiter",
      type: "advisory-board",
      position: <>Managing Member & CIO of Zuaiter Capital Holdings LLC</>,
      positionLong: <>Managing Member & Chief Investment Officer of Zuaiter Capital Holdings LLC</>,
      description:    <>
                          <p><strong>iMENA Group</strong> - Member of the Advisory Board<br />
                          <strong>The Capital Holdings Funds</strong> - Member of the Board of Directors<br />
                          <strong>Ossia</strong> -  Member of the Board of Directors<br />
                          <strong>Atom Investors</strong> - Member of the Advisory Board<br />
                          <strong>Alcazar Capital</strong> - Chairman of the Investment Committee<br />
                          <strong>CoinMENA</strong> - Member of the Board of Directors</p>
                          <p><strong>Education:</strong> Georgetown University, BSBA, Accounting & Finance </p>
                      </>,
      linkedinUrl: "https://www.linkedin.com/in/abbas-f-eddy-zuaiter-51832a9/",
      image: advisoryBoardPhoto5
    },
    {
      name: "Gary D. Rappaport",
      key: "gary-d-rappaport",
      type: "advisory-board",
      position: <>Chief Executive Officer of Rappaport</>,
      description:    <><p>Founder & CEO of Rappaport, a retail real estate company that provides leasing, tenant representation, management, and development for more than 15 million square feet of property.</p><p>Portfolio includes more than 50 shopping center and ground floor retail in some 100 mixed-use properties, both residential and office, located primarily throughout the mid-Atlantic region.</p><p>Former Chairman and Trustee of the International Council of Shopping Centers. </p></>,
      linkedinUrl: "https://www.linkedin.com/in/gary-rappaport-a6b338191/",
      image: advisoryBoardPhoto4
    },
    {
      name: "Stefan F. Tucker",
      key: "stefan-f-tucker",
      type: "advisory-board",
      position: <>Partner at Venable LLP</>,
      description:    <><p>Represents a wide range of clients on subjects from mergers & acquisitions, entity planning, structuring and formation, to asset preservation, and business transactions.</p><p>Member of the District of Columbia Bar Division of Taxation. Former Chair of the American Bar Association Section of Taxation (1998-1999). Former member of the ABA section of Taxation Task Force on tax system restructuring.</p><p>Former professorial lecturer at George Washington University Law Center. Former adjunct professor at Georgetown University Law Center. Member of the Board of Trustees of Massachusetts School of Law (Andover).</p></>,
      linkedinUrl: "https://www.linkedin.com/in/stefan-tucker-532a7a5/",
      image: advisoryBoardPhoto3
    },
    {
      name: "Dwayne C. Holt",
      key: "dwayne-c-holt",
      type: "advisory-board",
      position: <>Partner at Baker Tilly LLP</>,
      description:    <><p>Provides services across multiple industries, including real estate, finance, professional services, and technology.</p><p>Actively involved with matters pertaining to entity formation, succession planning, transaction structuring, asset protection, asset disposition strategies, and tax implications of day-to-day operational matters.</p><p>Executive Committee, Board of Directors, Treasurer of NAIOP, the Commercial Real Estate Development Association (Northern Virginia chapter). Executive Committee, Board of Directors, Treasurer of the District of Columbia Building Industry Association.</p></>,
      linkedinUrl: "https://www.linkedin.com/in/dwayne-holt-536121/",
      image: advisoryBoardPhoto2
    },
    {
      name: "Preston Woo",
      key: "preston-woo",
      type: "advisory-board",
      position: <>Chief Strategy Officer of Tafi</>,
      description:    <>
                          <p>CSO at Tafi, the leading 3D avatar provider for mobile, gaming, and mixed reality.</p>
                          <p><strong>Ossia</strong> - Member of the Board of Directors<br />
                              <strong>Recon Dynamics</strong> - Member of the Board of Directors.
                            </p>
                            <p>
                              <strong>Ossia</strong> - Former CSO<br />
                              <strong>Sarcos Robotics</strong> - Former CFO and Head of Corporate Development<br />
                              <strong>Pendrell Corporation</strong> - Former Head of Corporate Development<br />
                              <strong>Intellectual Ventures</strong> - Former head of Business Development<br />
                              <strong>Vulcan Capital</strong> - Former member of the private equity investment team 
                          </p>
                      </>,
      linkedinUrl: "https://www.linkedin.com/in/preston-woo-9458133",
      image: advisoryBoardPhoto1
    },
    {
      name: "Andrew D. Brown",
      key: "andrew-brown",
      type: "executive-management",
      position: <>General Counsel<br />Investment Committee</>,
      positionLong: <>General Counsel<br />Investment Committee</>,
      description: <>
                    <p>More than twenty years spent providing a range of expert legal advice to start-ups, entrepreneurial companies and multinationals.</p>
  
                    <p>Held senior legal roles in the biotechnology, oil and gas, telecommunications, financial services and property industries.</p>
                    <p>Experience of a multitude of jurisdictions including the UK, USA, Europe and the Middle East.</p>
  
                  </>,
      descriptionLong: <>
                    <p>More than twenty years spent providing a range of expert legal advice to start-ups, entrepreneurial companies and multinationals.</p>
  
                    <h5 className="mb-3 mt-5">Experience</h5>
                    <p>Held senior legal roles in the biotechnology, oil and gas, telecommunications, financial services and property industries.</p>
                    <p>Experience of a multitude of jurisdictions including the UK, USA, Europe and the Middle East.</p>
  
                    <p>Particular experience in fund-raising (both debt and equity), project financing, international joint ventures and mergers and acquisitions.</p>
  
                    <p>Serves as company secretary on a number of portfolio company Boards.</p>
                  </>,
      linkedinUrl: "https://www.linkedin.com/in/andrew-brown-54aab9",
      image: executiveManagementPhoto3
    },
    {
      name: "Randa F. Taqtaq",
      key: "randa-f-taqtaq",
      type: "executive-management",
      position: <>Global SVP of Finance<br />Investment Committee</>,
      positionLong: <>Global SVP of Finance<br />Investment Committee</>,
      description: <>
                    <p>Over two decades’ experience ranging from financial reporting and strategic planning, to corporate structuring, risk management, mergers, compliance and fund raising.</p>
  
                    <p>Executive Director of Finance for a leading real estate development & asset management company.</p>
                    <p>Provided Audit and Business Advisory services for both Arthur Andersen and Ernst & Young.</p>
                  </>,
      descriptionLong: <>
                    <p>Over two decades’ experience ranging from financial reporting and strategic planning, to corporate structuring, risk management, mergers, compliance and fund raising.</p>
  
                    <h5 className="mb-3 mt-5">Experience</h5>
                    <p>Executive Director of Finance for a leading real estate development & asset management company.</p>
                    <p>Provided Audit and Business Advisory services for both Arthur Andersen and Ernst & Young.</p>
  
                    <p>Spearheaded strategic assignments in Real Estate & Tourism firms.</p>
  
                    <p>Served as a member of the Board of Directors and audit committees for numerous private companies.</p>
                  </>,
  linkedinUrl: "https://www.linkedin.com/in/randa-taqtaq-43a760a/#",
      image: executiveManagementPhoto2
    },
    {
      name: "Patricia L. Bowditch",
      key: "patricia-l-bowditch",
      type: "executive-management",
      position: <>SVP of United States Operations<br />Investment Committee</>,
      positionLong: <>SVP of United States Operations<br />Investment Committee</>,
      description: <>
                    <p>A leading financial expert with over 35 years of experience in strategic planning, real estate transactions and management, forecasting, finance and financial compliance.</p>
                    <p>An adviser and consultant, guiding high-net-worth individuals and families, overseeing $500 million to $1 billion portfolios.</p>
  
                    <p>Managed portfolio of real estate investments as President, Dinwiddie Enterprises Inc.</p>
                    <p>Provided accounting, financial & risk management expertise as VP Finance, Realty Investment Co. & as CFO, Hardware Corporation of America.</p>
                  </>,
      descriptionLong: <>
                  <p>A leading financial expert with over 35 years of experience in strategic planning, real estate transactions and management, forecasting, finance and financial compliance.</p>
                  <p>An adviser and consultant, guiding high-net-worth individuals and families, overseeing $500 million to $1 billion portfolios.</p>
  
                  <h5 className="mb-3 mt-5">Experience</h5>
                  <p>Managed portfolio of real estate investments as President, Dinwiddie Enterprises Inc.</p>
                  <p>Provided accounting, financial & risk management expertise as VP Finance, Realty Investment Co. & as CFO, Hardware Corporation of America.</p>
  
                  <p>Developed internal control systems, financial projections & management reporting to large organizations as the owner of Bowditch & Associates LLC.</p>
  
                  <p>Served as a member of the board of directors and executive committee for private companies and charitable organizations.</p>
                </>,
      linkedinUrl: "https://www.linkedin.com/in/patricia-bowditch-84814b5",
      image: executiveManagementPhoto1
    }
  ];

  export default peopleData;

  