import React, { useState, useEffect } from 'react';

import { Row } from 'react-bootstrap';
import OrganizationCardExtended from './OrganizationCardExtended';

class OrganizationListExtended extends React.Component {
  
    render() {
        return <Row className="row-cols-2">
          {this.props.data.map( (organizationItem, organizationIndex) => (
              <OrganizationCardExtended key={organizationIndex} data={organizationItem} />
          ))}
        </Row>
    }
}

export default OrganizationListExtended;