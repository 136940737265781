const investmentLocationData = [
  // Investments
  {
    name: "North America",
    countries: [
      {
        name: "USA",
        cities: [
          { name: "Maryland" },
          { name: "Virginia" },
          { name: "Florida" },
          { name: "Chicago" },
          { name: "New York" },
          { name: "South Carolina" },
          { name: "North Carolina" },
          { name: "Texas" },
          { name: "Pennsylvania" },
          { name: "Washington D.C." }
        ]
      },
      { name: "Canada" }
    ]
  },
  {
    name: "South America",
    countries: [
      {name: "Brasil"},
      {name: "Chile"}
    ]
  },
  {
    name: "Europe",
    countries: [
      {name: "Germany"},
      {name: "Switzerland"},
      {name: "France"},
      {name: "Turkey"},
      {name: "Ireland"},
      {name: "United Kingdom"},
      {name: "Luxembourg"}
    ]
  },
  {
    name: "Asia",
    countries: [
      {name: "China"},
      {name: "Saudi Arabia"},
      {name: "Jordan"},
      {name: "Singapore"},
      {name: "Indonesia"},
      {name: "South Korea"},
      {name: "Myanmar"},
      {name: "United Arab Emirates"},
      {name: "Oman"},
      {name: "Vietnam"}
    ]
  }
];









export default investmentLocationData;

  