import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Footer from '../Elements/Footer';

import map from '../../assets/media/investment-locations/map.svg';

const AboutInvestmentLocations = (props) => {
    var footerPreviousPage = {link: "/about/investment-landscape", text: "Investment Landscape", index: 5};

    const accessSecure = localStorage.getItem('token');
    var footerNextPage;

    if( accessSecure ){
        footerNextPage = {link: "/portfolio/real-estate-and-hospitality/hospitality", text: "Hospitality", index: 7};
    }else{
        footerNextPage = {link: "/foundation", text: "Foundation", index: 13};
    }

    return (
        <>
            <h1><span>Investment</span><br />Locations</h1>

            <Row className="investment-locations">
                <Col xxl={9}>
                    <div className="investment-locations-graph" style={{'backgroundImage': 'url('+map+')'}}>
                        <p className="location-types"><span className="investments">Investments</span><span className="offices">Office</span></p>
                    </div>
                </Col>
                <Col xxl={3}>
                    <div className="location-list">
                    {
                        props.pageData.locations.map( (location, locationIndex) => (
                            <div key={locationIndex} className="mb-3">
                                <h5 className="mb-2 mt-5">{location.name}</h5>
                                <Row>
                                    { location.countries &&
                                        location.countries.map( (country, countryIndex) => (
                                            <Col key={countryIndex} xs={location.countries.length > 2 ? 6: 12} className="country-name">{country.name}</Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        ))
                    }
                    </div>
                </Col>
            </Row>

            <Footer previousPage={footerPreviousPage} nextPage={footerNextPage} />
        </>
    );
};

export default AboutInvestmentLocations;