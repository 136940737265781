import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';

import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import linkedinLogo from '../../assets/img/linkedin.svg';

class PersonCard extends React.Component {

    render() {
        return <Col xs={12} lg={6} xl={4}>
            <div className="person mb-5" style={{'backgroundImage': 'url('+this.props.data.image+')'}}>
                <div className="person-inner p-4">
                    <h3>{this.props.data.name}</h3>
                    <p className="mb-0">{this.props.data.position}</p>
                </div>
                <div className="person-details p-4">
                    <h3>{this.props.data.name}</h3>
                    <p className="subheader">{this.props.data.positionLong ? this.props.data.positionLong : this.props.data.position}</p>
                    {this.props.data.description}
                    <Row className="footer p-4">
                        <Col xs={6}><a target="_blank" href={this.props.data.linkedinUrl}><img src={linkedinLogo} /></a></Col>
                        {/* this.props.data.descriptionLong &&
                            <Col xs={6} className="text-end"><Link className="read-more" to={ `/management/profile/${this.props.data.key}`}>Read More Bio</Link></Col>
                        */ }
                    </Row>
                </div>
            </div>
        </Col>
    }
}

export default PersonCard;