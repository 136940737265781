// Relationships
import BlackstonePhoto from '../assets/media/portfolio/private-equity/relationships/Blackstone.png';
import BPEAPhoto from '../assets/media/portfolio/private-equity/relationships/BPEA.png';
import DLEPhoto from '../assets/media/portfolio/private-equity/relationships/DLE.png';
import EQTPhoto from '../assets/media/portfolio/private-equity/relationships/EQT.png';
import FraizerPhoto from '../assets/media/portfolio/private-equity/relationships/Fraizer.png';
import GlendowerCapitalPhoto from '../assets/media/portfolio/private-equity/relationships/GlendowerCapital.png';
import HighlandPhoto from '../assets/media/portfolio/private-equity/relationships/Highland.png';
import KainosPhoto from '../assets/media/portfolio/private-equity/relationships/Kainos.png';
import OaktreePhoto from '../assets/media/portfolio/private-equity/relationships/Oaktree.png';
import SandbridgePhoto from '../assets/media/portfolio/private-equity/relationships/Sandbridge.png';
import SearchlightPhoto from '../assets/media/portfolio/private-equity/relationships/Searchlight.png';
import TPGPhoto from '../assets/media/portfolio/private-equity/relationships/TPG.png';
import TrinityPhoto from '../assets/media/portfolio/private-equity/relationships/Trinity.png';
import WarburgPincusPhoto from '../assets/media/portfolio/private-equity/relationships/WarburgPincus.png';
import ThomaBravoPhoto from '../assets/media/portfolio/private-equity/relationships/Thomabravo.png';
import GenstarCapitalPhoto from '../assets/media/portfolio/private-equity/relationships/Genstar.png';
import BlueMarlinPhoto from '../assets/media/portfolio/private-equity/relationships/BlueMarlin.png';
import TriSpanPhoto from '../assets/media/portfolio/private-equity/relationships/TriSpan.png';
import SpauldingPhoto from '../assets/media/portfolio/private-equity/relationships/Spaulding.png';
import LexingtonPartnersPhoto from '../assets/media/portfolio/private-equity/relationships/LexingtonPartners.png';

// Investments
import _Photo from '../assets/media/portfolio/private-equity/investments/_.png';
import PanoramaEyecarePhoto from '../assets/media/portfolio/private-equity/investments/PanoramaEyecare.png';
import BojanglesPhoto from '../assets/media/portfolio/private-equity/investments/Bojangles.png';
import IrrawaddyPhoto from '../assets/media/portfolio/private-equity/investments/Irrawaddy.png';
import ITCBroadbandPhoto from '../assets/media/portfolio/private-equity/investments/ITCBroadband.png';
import MavisDiscountTirePhoto from '../assets/media/portfolio/private-equity/investments/MavisDiscountTire.png';
import MissionBBQPhoto from '../assets/media/portfolio/private-equity/investments/MissionBBQ.png';
import NayaPhoto from '../assets/media/portfolio/private-equity/investments/Naya.png';
import PrestigePEOPhoto from '../assets/media/portfolio/private-equity/investments/PrestigePEO.png';
import SupermarketPhoto from '../assets/media/portfolio/private-equity/investments/Supermarket.png';
import ResolutionLifePhoto from '../assets/media/portfolio/private-equity/investments/ResolutionLife.png';

const privateEquityData = [
  // Investments
  {
    type: "Investments",
    image: BojanglesPhoto,
    name: "Bojangles",
    link: "https://www.bojangles.com",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: SupermarketPhoto,
    name: "BIYIDE CHINA",
    link: "http://biyide.cn/?lang=en",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: IrrawaddyPhoto,
    name: "Irrawaddy",
    link: "https://igt.com.mm",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: ITCBroadbandPhoto,
    name: "ITC Broadband",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: MavisDiscountTirePhoto,
    name: "Mavis",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: MissionBBQPhoto,
    name: "Mission BBQ",
    link: "https://mission-bbq.com",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: NayaPhoto,
    link: "https://eatnaya.com",
    name: "Naya",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: PanoramaEyecarePhoto,
    name: "Panorama Eye Care",
    link: "https://panoramaeyecare.com/",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: PrestigePEOPhoto,
    name: "Prestige",
    link: "https://www.prestigepeo.com",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: ResolutionLifePhoto,
    name: "Resolution Life",
    link: "https://www.resolutionlife.com/",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: _Photo,
    name: "Superfreeze Korea",
    link: "http://www.superfreeze.kr/main/main.html",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
  },
  // Relationships
  {
    type: "Relationships",
    image: BlackstonePhoto,
    name: "Blacksone",
    description: "Control and co-control equity investments within healthcare, TMT, services, and industrial technology"
  },
  {
    type: "Relationships",
    image: BlueMarlinPhoto,
    link: "https://www.bluemarpartners.com/"
  },
  {
    type: "Relationships",
    image: BPEAPhoto,
    name: "BPEA",
    description: "Value-add hospitality assets throughout the United States with a focus on full-service hotels."
  },
  {
    type: "Relationships",
    image: DLEPhoto,
    name: "DLE Landbanking Fund III (Berlin)",
    description: "Landbanking in the metropolitan region of Berlin. Strategy of acquiring plots of land without a building"
  },
  {
    type: "Relationships",
    image: EQTPhoto,
    name: "EQT Fund IX",
    description: "Control and co-control equity investments within healthcare, TMT, services, and industrial technology"
  },
  {
    type: "Relationships",
    image: FraizerPhoto,
    name: "Fraizer",
    description: "Value-add hospitality assets throughout the United States with a focus on full-service hotels."
  },
  {
    type: "Relationships",
    image: GlendowerCapitalPhoto,
    name: "Glendower Capital Fund V",
    description: "A mid-size, value-oriented player solely focused on secondaries with an emphasis on North America and ="
  },
  {
    type: "Relationships",
    image: GenstarCapitalPhoto,
    link: "https://www.gencap.com/"
  },
  {
    type: "Relationships",
    image: LexingtonPartnersPhoto,
    link: "https://www.lexingtonpartners.com/"
  }, 
  {
    type: "Relationships",
    image: OaktreePhoto,
    name: "Oaktree",
    description: "Value-add hospitality assets throughout the United States with a focus on full-service hotels. "
  },
  {
    type: "Relationships",
    image: SandbridgePhoto,
    name: "Sandbridge",
    description: "Consumer Fund II & III, L.P. Interests in private companies providing goods and services to consumers."
  },
  {
    type: "Relationships",
    image: SearchlightPhoto,
    name: "Searchlight",
    description: "Value-add hospitality assets throughout the United States with a focus on full-service hotels."
  },
  {
    type: "Relationships",
    image: SpauldingPhoto,
    link: "https://ssinvests.com/"
  },
  {
    type: "Relationships",
    image: ThomaBravoPhoto,
    link: "https://www.thomabravo.com/"
  }, 
  {
    type: "Relationships",
    image: TrinityPhoto,
    name: "Trinity GP Fund I L.P.",
    description: "Value-add hospitality assets throughout the United States with a focus on full-service hotels."
  }, 
  {
    type: "Relationships",
    image: TriSpanPhoto,
    link: "https://www.trispanllp.com/"
  }, 
  {
    type: "Relationships",
    image: WarburgPincusPhoto,
    name: "Warburg Pincus Asia Real Estate, L.P.",
    description: "Opportunistic real estate throughout pan-Asia across key sectors including industrial, logistics, data centers, "
  }
];

  export default privateEquityData;

  