import React, { useState, useEffect } from 'react';

import { Row } from 'react-bootstrap';
import OrganizationCard from './OrganizationCard';

class OrganizationList extends React.Component {
  
    render() {
        return <Row className="row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2">
          {this.props.data.map( (organizationItem, organizationIndex) => (
              <OrganizationCard key={organizationIndex} data={organizationItem} />
          ))}  
        </Row>
    }
}

export default OrganizationList;