import React, { useState, useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';


class OrganizationCardExtended extends React.Component {

    render() {
        return <Col className="organization mb-1 text-center">
            <Row>
                <Col sm={4}><div className="image" style={{backgroundImage: `url(${this.props.data.image})`}}></div></Col>
                <Col sm={7} className="text-start">
                    <p className="mb-0"><strong className="accent">{this.props.data.name}</strong></p>
                    <p className="mb-0">{this.props.data.description}</p>
                </Col>
            </Row>
        </Col>
    }
}

export default OrganizationCardExtended;