import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';

import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import linkedinLogo from '../../assets/img/linkedin.svg';

class PortfolioCard extends React.Component {

    render() {
        return <Col xs={12} lg={6} className="portfolio-item mb-4">
            <Row>
                <Col xs={6} lg={5}><img src={this.props.data.image} /></Col>
                <Col xs={6} lg={6}>
                    <h6>{this.props.data.name}</h6>
                    {this.props.data.description}
                </Col>
            </Row>
        </Col>
    }
}

export default PortfolioCard;