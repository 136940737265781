// Investments
import AmplePhoto from '../assets/media/portfolio/venture-capital/investments/Ample.png';
import BoosterPhoto from '../assets/media/portfolio/venture-capital/investments/Booster.png';
import BukalapakPhoto from '../assets/media/portfolio/venture-capital/investments/Bukalapak.png';
import ExtensyaPhoto from '../assets/media/portfolio/venture-capital/investments/Extensya.png';
import FigurePhoto from '../assets/media/portfolio/venture-capital/investments/Figure.png';
import FigmentPhoto from '../assets/media/portfolio/venture-capital/investments/Figment.png';
import GeminiPhoto from '../assets/media/portfolio/venture-capital/investments/Gemini.png';
import GrubMarketPhoto from '../assets/media/portfolio/venture-capital/investments/GrubMarket.png';
import HumacytePhoto from '../assets/media/portfolio/venture-capital/investments/Humacyte.png';
import InvstrPhoto from '../assets/media/portfolio/venture-capital/investments/Invstr.png';
import OssiaPhoto from '../assets/media/portfolio/venture-capital/investments/Ossia.png';
import PalantirPhoto from '../assets/media/portfolio/venture-capital/investments/Palantir.png';
import SellAnyCarPhoto from '../assets/media/portfolio/venture-capital/investments/SellAnyCar.png';
import SnackpassPhoto from '../assets/media/portfolio/venture-capital/investments/Snackpass.png';
import ThrasioPhoto from '../assets/media/portfolio/venture-capital/investments/Thrasio.png';
import ToastPhoto from '../assets/media/portfolio/venture-capital/investments/Toast.png';
import UberPhoto from '../assets/media/portfolio/venture-capital/investments/Uber.png';
import NowMoneyPhoto from '../assets/media/portfolio/venture-capital/investments/NowMoney.png';
import WeFoxPhoto from '../assets/media/portfolio/venture-capital/investments/WeFox.png';
import XcomPhoto from '../assets/media/portfolio/venture-capital/investments/Xcom.png';
import HeroesPhoto from '../assets/media/portfolio/venture-capital/investments/Heroes.png';
import UniphorePhoto from '../assets/media/portfolio/venture-capital/investments/Uniphore.png';
import ZirAIPhoto from '../assets/media/portfolio/venture-capital/investments/ZirAI.png';
import CollectivePhoto from '../assets/media/portfolio/venture-capital/investments/Collective.png';
import BoxBroadbandPhoto from '../assets/media/portfolio/private-equity/investments/BoxBroadband.png';
import Yayem from '../assets/media/portfolio/venture-capital/investments/Yayem.png';
import Angellist from '../assets/media/portfolio/venture-capital/investments/Angellist.png';
import Crusoe from '../assets/media/portfolio/venture-capital/investments/Crusoe.png';

// Relationships
import AccessBridgeVenturesPhoto from '../assets/media/portfolio/venture-capital/relationships/AccessBridgeVentures.png';
import AmebaCapitalPhoto from '../assets/media/portfolio/venture-capital/relationships/AmebaCapital.png';
import BlockchainPhoto from '../assets/media/portfolio/venture-capital/relationships/Blockchain.png';
import EVPhoto from '../assets/media/portfolio/venture-capital/relationships/EV.png';
import GrubtechPhoto from '../assets/media/portfolio/venture-capital/relationships/Grubtech.png';
import NorthgatePhoto from '../assets/media/portfolio/venture-capital/relationships/Northgate.png';
import TargetGlobalPhoto from '../assets/media/portfolio/venture-capital/relationships/TargetGlobal.png';
import TCPPhoto from '../assets/media/portfolio/venture-capital/relationships/TCP.png';
import TMVCapitalPhoto from '../assets/media/portfolio/venture-capital/relationships/TMVCapital.png';
import HOFPhoto from '../assets/media/portfolio/venture-capital/relationships/HOF.png';
import KittyHawkPhoto from '../assets/media/portfolio/venture-capital/relationships/KittyHawk.png';
import Cotu from '../assets/media/portfolio/venture-capital/relationships/Cotu.png';

const ventureCapitalData = [
  // Investments
  {
    type: "Investments",
    image: AmplePhoto,
    link: "https://ample.com"
  },
  {
    type: "Investments",
    image: Angellist,
    link: "https://www.angellist.com/"
  },
  {
    type: "Investments",
    image: BoosterPhoto,
    link: "https://boosterusa.com"
  },
  {
    type: "Investments",
    image: BukalapakPhoto,
    link: "https://about.bukalapak.com/en/"
  },
  {
    type: "Investments",
    image: BoxBroadbandPhoto,
    name: "Box Broadband",
    link: "https://www.boxbroadband.co.uk",
    description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
  },
  {
    type: "Investments",
    image: CollectivePhoto,
    link: "https://www.collective.com/"
  },
  {
    type: "Investments",
    image: Crusoe,
    link: "https://www.crusoeenergy.com/"
  },
  {
    type: "Investments",
    image: ExtensyaPhoto,
    link: "https://www.extensya.com"
  },
  {
    type: "Investments",
    image: FigurePhoto,
    link: "https://www.figure.com"
  },
  {
    type: "Investments",
    image: FigmentPhoto,
    link: "https://www.figment.io"
  },
  {
    type: "Investments",
    image: GeminiPhoto,
    link: "https://www.gemini.com"
  },
  {
    type: "Investments",
    image: GrubMarketPhoto,
    link: "https://www.grubmarket.com/welcome"
  },
  {
    type: "Investments",
    image: GrubtechPhoto,
    link: "https://www.grubtech.com"
  },
  {
    type: "Investments",
    image: HeroesPhoto,
    link: "https://www.makeheroes.co/"
  },
  {
    type: "Investments",
    image: HumacytePhoto,
    link: "https://humacyte.com"
  },
  {
    type: "Investments",
    image: InvstrPhoto,
    link: "https://invstr.com"
  },
  {
    type: "Investments",
    image: NowMoneyPhoto,
    link: "https://nowmoney.me"
  },
  {
    type: "Investments",
    image: OssiaPhoto,
    link: "https://www.ossia.com"
  },
  {
    type: "Investments",
    image: PalantirPhoto,
    link: "https://www.palantir.com"
  },
  {
    type: "Investments",
    image: SellAnyCarPhoto,
    link: "https://uae.sellanycar.com"
  },
  {
    type: "Investments",
    image: ThrasioPhoto,
    link: "https://www.thrasio.com"
  },
  {
    type: "Investments",
    image: ToastPhoto,
    link: "https://pos.toasttab.com"
  },
  {
    type: "Investments",
    image: UberPhoto,
    link: "https://www.uber.com"
  },
  {
    type: "Investments",
    image: UniphorePhoto,
    link: "https://www.uniphore.com/"
  },
  {
    type: "Investments",
    image: WeFoxPhoto,
    link: "https://www.wefox.com/en-ch"
  },
  {
    type: "Investments",
    image: XcomPhoto,
    link: "https://www.xcom-labs.com"
  },
  {
    type: "Investments",
    image: Yayem,
    link: "https://www.yayem.co/"
  },
  {
    type: "Investments",
    image: ZirAIPhoto,
    link: "https://zir-ai.com/"
  },
  // Relationships
  {
    type: "Relationships",
    image: AccessBridgeVenturesPhoto,
    link: "https://access-bridge.com"
  },
  {
    type: "Relationships",
    image: AmebaCapitalPhoto,
    link: "http://www.amebacapital.com/#/en"
  },
  {
    type: "Relationships",
    image: BlockchainPhoto,
    link: "https://blockchain.capital"
  },
  {
    type: "Relationships",
    image: Cotu,
    link: "https://www.cotu.vc/"
  },
  {
    type: "Relationships",
    image: EVPhoto,
    link: "https://www.escondidoventures.com"
  },
  {
    type: "Relationships",
    image: HOFPhoto,
    link: "https://www.hofvc.com"
  },
  {
    type: "Relationships",
    image: KittyHawkPhoto,
    link: "https://www.kittyhawk.vc/"
  },
  {
    type: "Relationships",
    image: NorthgatePhoto,
    link: "http://northgate.com"
  },
  {
    type: "Relationships",
    image: TargetGlobalPhoto,
    link: "https://www.targetglobal.vc"
  },
  {
    type: "Relationships",
    image: TCPPhoto,
    link: "https://www.tcpam.com"
  },
  {
    type: "Relationships",
    image: TMVCapitalPhoto,
    link: "https://www.tvm-lifescience.com"
  },
];

export default ventureCapitalData;

  