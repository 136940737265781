import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../Elements/Footer';

const Profile = (props) => {
    const { namekey } = useParams();

    var currentPageData = null;
        
    props.pageData.people.forEach(person => {
        if( person.key === namekey )
        {
            currentPageData = person;
        }
    });

    if( currentPageData )
    {
        if( currentPageData.type == 'advisory-board' )
        {
            var footerPreviousPage = {link: "/management/advisory-board", text: "Advisory Board", index: 2};
        }
        else if( currentPageData.type == 'executive-management' )
        {
            var footerPreviousPage = {link: "/management/management", text: "Executive Management", index: 3};
        }
    }

    return (
        <>
        { currentPageData &&
            <>
                <Row>
                    <Col s={6}>
                        <h1>{currentPageData.name}</h1>
                        <p><strong>{currentPageData.positionLong}</strong></p>
                        {currentPageData.descriptionLong}
                    </Col>
                    <Col s={6} className="text-end">
                        <img className="principal-photo" src={currentPageData.image} />
                    </Col>
                </Row>

                <Footer previousPage={footerPreviousPage} />
            </>
        }
        </>
    );
};

export default Profile;