import React, { useState, useEffect } from 'react';

import Home from './Components/Pages/Home';
import About from './Components/Pages/About';
import AboutInvestmentLandscape from './Components/Pages/AboutInvestmentLandscape';
import AboutInvestmentLocations from './Components/Pages/AboutInvestmentLocations';
import Profile from './Components/Pages/Profile';
import Management from './Components/Pages/Management';
import ManagementManagement from './Components/Pages/ManagementManagement';
import ManagementAdvisory from './Components/Pages/ManagementAdvisory';
import PortfolioOverview from './Components/Pages/PortfolioOverview';
import PortfolioRealEstateAndHospitality from './Components/Pages/PortfolioRealEstateAndHospitality';
import PortfolioVentureCapitalPrivateEquity from './Components/Pages/PortfolioVentureCapitalPrivateEquity';
import PortfolioVentureCapitalPrivateEquityExtended from './Components/Pages/PortfolioVentureCapitalPrivateEquityExtended';
import Foundation from './Components/Pages/Foundation';
import Contact from './Components/Pages/Contact';

import Wrapper from './Components/Elements/Wrapper';
import Header from './Components/Elements/Header';

import { Container } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group'

import './App.css';

import peopleData from './data/people.js';
import portfolioData from './data/portfolio.js';
import ventureCapitalData from './data/venture-capital.js';
import privateEquityData from './data/private-equity.js';
import foundationData from './data/foundation.js';
import investmentLocationsData from './data/investment-locations.js';

import PropTypes from "prop-types";

import {
  BrowserRouter as Router,
  Route,
  withRouter
} from "react-router-dom";



class AppMain extends React.Component{

  static propTypes = {
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const advisoryBoardPeople = peopleData.filter( i => ['advisory-board'].includes( i.type ) );
    const executiveManagementPeople = peopleData.filter( i => ['executive-management'].includes( i.type ) );
  
    this.routes = [
      {path: "/", name: "Home", Component: Home},
      {path: "/about", name: "About", Component: About},
      {path: "/about/investment-landscape", name: "About", Component: AboutInvestmentLandscape},
      {path: "/about/investment-locations", name: "About", Component: AboutInvestmentLocations, pageData: {locations: investmentLocationsData}},
      {path: "/management", name: "Management: Principal", Component: Management},
      {path: "/management/advisory-board", name: "Management: Advisory", Component: ManagementAdvisory, pageData: {people: advisoryBoardPeople}},
      {path: "/management/management", name: "Management: Executive Management", Component: ManagementManagement, pageData: {people: executiveManagementPeople}},
      {path: "/management/profile/:namekey", name: "Management", Component: Profile, pageData: {people: peopleData}},
      {path: "/portfolio", name: "Portfolio", Component: PortfolioOverview},
      {path: "/portfolio/real-estate-and-hospitality/:typeKey/:locationKey?", name: "Portfolio: Real Estate & Hospitality", Component: PortfolioRealEstateAndHospitality, pageData: {portfolio: portfolioData}},
      {path: "/portfolio/venture-capital/:typeKey?", name: "Portfolio: Venture Capital", Component: PortfolioVentureCapitalPrivateEquity, pageData: {organizations: ventureCapitalData, type: 'venture-capital'}},
      {path: "/portfolio/private-equity/:typeKey?", name: "Portfolio: Private Equity", Component: PortfolioVentureCapitalPrivateEquity, pageData: {organizations: privateEquityData, type: 'private-equity'}},
      {path: "/portfolio/private-equity-extended/:typeKey?", name: "Portfolio: Private Equity", Component: PortfolioVentureCapitalPrivateEquityExtended, pageData: {organizations: privateEquityData, type: 'private-equity'}},
      {path: "/foundation", name: "Foundation", Component: Foundation, pageData: {organizations: foundationData}},
      {path: "/contact", name: "Contact", Component: Contact}
    ];
  
  
    this.navigationList = [
      // About
      {
        name: "About",
        path: "/about"
      },
      // Management
      {
        name: "Management",
        path: "/management",
        childPages: [
          {
            name: "Principal",
            path: "/management"
          },
          {
            name: "Advisory Board",
            path: "/management/advisory-board"
          },
          {
            name: "Executive Management",
            path: "/management/management"
          }
        ]
      },
      // Portfolio
      {
        name: "Portfolio",
        path: "/about/investment-landscape",
        childPages: [
          {
            name: "Investment Landscape",
            path: "/about/investment-landscape",
          },
          {
            name: "Investment Locations",
            path: "/about/investment-locations",
          },
          {
            name: "Real Estate & Hospitality",
            path: "/portfolio/real-estate-and-hospitality/hospitality",
            secure: true,
            childPages: [
              {
                name: "Hospitality",
                path: "/portfolio/real-estate-and-hospitality/hospitality"
              },
              {
                name: "Office",
                path: "/portfolio/real-estate-and-hospitality/office"
              },
              {
                name: "Retail",
                path: "/portfolio/real-estate-and-hospitality/retail"
              },
              {
                name: "Residential",
                path: "/portfolio/real-estate-and-hospitality/residential"
              }
            ]
          },
          {
            name: "Private Equity",
            path: "/portfolio/private-equity",
            secure: true,
            childPages: [
              {
                name: "Investments",
                path: "/portfolio/private-equity/investments"
              },
              {
                name: "Relationships",
                path: "/portfolio/private-equity/relationships"
              }
            ]
          },
          {
            name: "Venture Capital",
            path: "/portfolio/venture-capital",
            secure: true,
            childPages: [
              {
                name: "Investments",
                path: "/portfolio/venture-capital/investments"
              },
              {
                name: "Relationships",
                path: "/portfolio/venture-capital/relationships"
              }
            ]
          }
        ]
      },
      // Foundation
      {
        name: "Foundation",
        path: "/foundation"
      }
    ];
  
  }
  
  render() {
    const { location } = this.props;

    if( location.search )
    {
      const urlParams = new URLSearchParams(location.search);

      if( urlParams.get('token') )
      {
        localStorage.setItem('token', urlParams.get('token'));
      }

      if( urlParams.get('clearToken') )
      {
        localStorage.removeItem('token');
      }
    }

    let locationFormatted = location.pathname.substring(1).replaceAll('/', '-');
        locationFormatted = locationFormatted.length === 0 ? 'home' : locationFormatted;

    document.body.setAttribute('data-page', locationFormatted);

    return <Wrapper>

        <Header links={this.navigationList} />

        <Container className="container">

          {this.routes.map(({ path, Component, pageData }) => (
            <Route key={path} exact path={path}>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={300}
                  classNames="page"
                  unmountOnExit
                >
                  <div className="page">
                    <Component pageData={pageData} />
                  </div>
                </CSSTransition>
              )}
            </Route>
          ))}

        </Container>


      </Wrapper>

  }
}
const App = withRouter(AppMain);

export default App;