import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import principalPhoto from '../../assets/media/management/principal.jpg'
import Footer from '../Elements/Footer';

const Management = () => {
    var footerPreviousPage = {link: "/about", text: "About", index: 1};
    var footerNextPage = {link: "/management/advisory-board", text: "Advisory Board", index: 3};
    const [toggle, setToggle] = useState(false);

    const toggleOn = (e) => {
        e.preventDefault();
        setToggle(true);
    }

    return (
        <>
        <Row>
            <Col md={6}>
                <div className={`principal-container${toggle ? ' principal-container-expanded' : ''}`}>
                    {
                        toggle ?
                            <>
                                                    <h1>Ali H. Kolaghassi</h1>
                        <p><strong>Founder & President of Kolaghassi Capital Ltd.</strong><br /><strong>Chairman of Kolaghassi International Holdings</strong></p>

                                <p>With a vision that focuses on assessing and investing in businesses with high growth and revenue potential, Ali Kolaghassi, through KC, fully utilizes his talents for business creation and development to identify and pursue opportunities from concept through to implementation and exit. </p>
                                <p>Mr Kolaghassi has had an illustrious career with over 29 years of leadership and management experience in international corporate business development, with a particular focus on construction, real estate development, asset management, private investments, banking and telecommunications.</p>
{
    /*

                                <h5 className="mb-3 mt-5">Boards</h5>
                                <p><strong className="accent">Ossia</strong> - Chairman of the Board of Directors</p>
                                <p><strong className="accent">Alcazar Capital</strong> - Member of the Board of Directors</p>

                                <h5 className="mb-3 mt-5">Non-for-profit organizations</h5>
                                <p><strong className="accent">King’s Academy</strong> - Chairman of the Board of Trustees</p>
                                <p><strong className="accent">The George Washington University</strong> - Member of the Board of Trustees</p>
                                <p><strong className="accent">King Hussien Cancer Center</strong> - Member of the Board of Trustees</p>
*/
                            }
                            </>
                        :
                            <>
                                <h1 className="pb-lg-2"><span>Principal</span></h1>
                                <h2 className="mt-lg-5 pt-3">Ali H. Kolaghassi</h2>
                                <p>Founder & President of Kolaghassi Capital Ltd.</p>
                                <p className="accent-link"><a href="" component={Link} onClick={toggleOn}>Read Bio</a></p>
                            </>
                }
                </div>
            </Col>
            <Col md={6} className="text-center text-lg-end">
                <img className="principal-photo" src={principalPhoto} />
            </Col>
        </Row>

        <Footer previousPage={footerPreviousPage} nextPage={footerNextPage} />

        </>
    );
};

export default Management;