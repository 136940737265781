import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import AboutPhoto1 from '../../assets/media/about/photo1.png';
import AboutPhoto2 from '../../assets/media/about/photo2.png';
import AboutPhoto3 from '../../assets/media/about/photo3.png';
import AboutPhoto4 from '../../assets/media/about/photo4.png';
import Footer from '../Elements/Footer';

const About = () => {
    var footerPreviousPage = {link: "", text: "Main"};
    var footerNextPage = {link: "/management", text: "Principle", index: 2};

    return (
        <>
        <Row>
            <Col sm={12} md={6} xxl={6}>
                <div className="welcome-container">
                    <h1>About<br /><span>Kolaghassi Capital</span></h1>
                    <p>Established in 2014 the firm manages investments in <strong>21 countries across four continents</strong>.</p>
                    <p>The group's investment strategy is focused on commercial & residential real estate, private equity, venture capital, and public market transactions.</p>
                    <p>With a small focused team and with immediate access to the principals' capital, <strong>KC</strong> is able to provide investment decisions faster than a typical firm.</p>
                </div>       
            </Col>
            <Col sm={12} md={6} xxl={6}>
                <div className="about-gallery">
                    <Row>
                        <Col sm={12} lg={6}><img className="mb-4" src={AboutPhoto4} /><img src={AboutPhoto3} /></Col>
                        <Col sm={12} lg={6} className="pt-2"><img className="mt-5 mb-4" src={AboutPhoto2} /><img src={AboutPhoto1} /></Col>
                    </Row>
                </div>
            </Col>
        </Row>

        <Footer previousPage={footerPreviousPage} nextPage={footerNextPage} />
        </>
    );
};

export default About;