import React, { useState, useEffect } from 'react';

import { Row } from 'react-bootstrap';

import PersonCard from './PersonCard';

class PersonList extends React.Component {
  
    render() {
        return <Row>
          {this.props.data.map( (person, personIndex) => (
              <PersonCard key={personIndex} data={person} />
          ))}  
        </Row>
    }
}

export default PersonList;