import React, { useState, useEffect } from 'react';
import PersonCard from '../Elements/PersonCard';
import PersonList from '../Elements/PersonList';
import Footer from '../Elements/Footer';

const ManagementAdvisory = (props) => {
    var footerPreviousPage = {link: "/management", text: "Principal", index: 2};
    var footerNextPage = {link: "/management/management", text: "Management", index: 4};

    return (
        <>
            <h1>Advisory <span>Board</span></h1>
            <PersonList data={props.pageData.people} />
            <Footer previousPage={footerPreviousPage} nextPage={footerNextPage} />
        </>
    );
};

export default ManagementAdvisory;