import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import Footer from '../Elements/Footer';
import OrganizationListExtended from '../Elements/OrganizationListExtended';
import slugify from 'react-slugify';
import { Redirect, useParams } from 'react-router';

import {
    Link,
    NavLink,
    useLocation
} from "react-router-dom";


const PortfolioVentureCapitalPrivateEquityExtended = (props) => {
    const { typeKey } = useParams();

    const types = [];

    props.pageData.organizations.forEach(element => {
        if( !types.includes(element.type) )
        {
            types.push(element.type);
        }
    });

    var selectedType = typeKey;

    if( !selectedType )
    {
        selectedType = slugify(types[0]);
    }

    var organizationDataFiltered = props.pageData.organizations.filter( i => [selectedType].includes( slugify(i.type) ) );

    if( props.pageData.type === 'private-equity' )
    {
        var footerPreviousPage = {link: "/portfolio/real-estate-and-hospitality/residential", text: "Residential", index: 4};
        var footerNextPage = {link: "/portfolio/venture-capital", text: "Venture Capital", index: 6};
    }
    else if( props.pageData.type === 'venture-capital' )
    {
        var footerPreviousPage = {link: "/portfolio/private-equity", text: "Private Equity", index: 5};
        var footerNextPage = {link: "/foundation", text: "Foundation", index: 7};    
    }

    return (
        <>
            <h1>{props.pageData.type === 'private-equity' ? 'Private Equity' : 'Venture Capital'}</h1>
            
            <Nav className="tabs">
            { types &&
                types.map( (type, typeIndex) => (
                    <Nav.Item key={typeIndex}>
                        <Nav.Link className={ `nav-link${slugify(type) === selectedType ? ' active' : ''}`} as={NavLink} to={ `/portfolio/${props.pageData.type}-extended/${slugify(type)}`}>{type}</Nav.Link>
                    </Nav.Item>
                ))
            }
            </Nav>

            <OrganizationListExtended data={organizationDataFiltered} />
            <Footer previousPage={footerPreviousPage ? footerPreviousPage : null} nextPage={footerNextPage ? footerNextPage : null} />
        </>
    );
};

export default PortfolioVentureCapitalPrivateEquityExtended;